import React from "react";
import TopNavBar from "../TopNavBar/TopNavBar";
import Header from "./components/Header";
import GateWayText from "./components/GateWayText";
import "./components/scroll";
import Courses from "./components/Courses";
import TrustedTeams from "./components/TrustedTeams";
import Stories from "./components/Stories";
import Teams from "./components/Teams";
import WhyUs from "./components/WhyUs";
import styled from "styled-components";
import Footer from "./components/Footer";
import About from "./components/About";
import TopBar from "./components/TopBar";
import { Button } from "@mui/material";
import OurSponsorship from "../Sponsorship/OurSponsorship";
export default function LandingPage() {
  return (
    <div style={{ overflow: "hidden" }}>
      <TopNavBar />
      <Header id="headerSection" />
      <Wrapper id="CoursesSection">
        {/* <div className="animation">
          <div className="anm_mod full fast"> */}
        <Courses />
        {/* </div>
        </div> */}
      </Wrapper>
      <Wrapper style={{ padding: "30px 0px" }} id="TrustedSection">
        <div className="animation">
          <div className="anm_mod full fast">
            <TrustedTeams />
          </div>
        </div>
      </Wrapper>
      <Wrapper id="storySection">
        <Stories />
      </Wrapper>
      <Wrapper id="aboutSection">
        <div className="animation">
          <div className="anm_mod full fast">
            <About />
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="animation">
          <div className="anm_mod full fast">
            {/* <Teams /> */}
          </div>
        </div>
      </Wrapper>
       
      <Wrapper>
      <div className="anm_mod full fast">
        <OurSponsorship/>
        </div>
      </Wrapper>

      <div style={{ background: "#ebeaf8", padding: "0px" }}>
        <WhyUs />
      </div>
      
      <Wrapper id="contactSection">
        <Footer />
      </Wrapper>
    
      {/* <div class="animation">
        <div class="anm_mod left fast">anm_mod left fast</div>
        <div class="anm_mod right delay">anm_mod right delay</div>
      </div>

      <div class="animation">
        <div class="anm_mod full fast">anm_mod full fast</div>
      </div>

      <div class="animation">
        <div class="anm_mod left delay">anm_mod left delay</div>
        <div class="anm_mod right fast">anm_mod right fast</div>
      </div>

      <div class="animation">
        <div class="anm_mod full delay">anm_mod full delay</div>
      </div> */}
      <Button
        href="https://wa.me/916360959192"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </Button>
    </div>
  );
}
const Wrapper = styled.div`
  padding: 30px 60px;
  @media (max-width: 760px) {
    padding: 10px;
  }
`;
