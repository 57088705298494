import * as React from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./hooks/ScrollToTop";
import Disclaimer from "src/screens/Disclaimer/Disclaimer";
import Policy from "src/screens/Policy/Policy";
import Conditions from "src/screens/Conditions/Conditions";
import Login from "src/screens/Login/Login";
import Taglogin from "src/screens/Taglogin/Taglogin";
import Coursevideo from "src/screens/Coursevideo/Coursevideo";
import Notification from "src/screens/Notification/Notification";
import Videoplay from "src/screens/Videoplay/Videoplay";
import { useSelector } from "react-redux";
import Pdfmaterials from "src/screens/Pdfmaterials/Pdfmaterials";
import Payment from "src/screens/Dashboard/Payment";
import Paystatus from "src/screens/Dashboard/Paystatus";
import Reviewall from "src/screens/Reviewall/Reviewall";
import Certificate from "src/screens/Certificate/Certificate";
import Validatecertificate from "src/screens/Validatecertificate/Validatecertificate";
import Main from "src/screens/Main/Main";
import ProtectedRoute from "src/api/ProtectedRoute";
import { UserAuthContextProvider } from "src/api//UserAuthContext";
import LandingPage from "./screens/LandingPage/LandingPage";
import "src/styles.css";
import CourseDetails from "./screens/LandingPage/components/CourseDetails";
import JobDetails from "./screens/LandingPage/components/JobDetails";
import DashboardRevamp from "./screens/DashboardRevamp";
import ProfileRevamp from "./screens/Profile/ProfileRevamp";
import Admission from "./screens/LandingPage/components/Admission";
import CustomPayment from "./screens/LandingPage/components/CustomPayment";
import Pay from "./screens/LandingPage/components/Pay";
import Connect from "./screens/LandingPage/components/Connect";
import Contactus from "./screens/LandingPage/components/Contactus";
import AddYourReview from "./screens/LandingPage/components/AddYourReview";
import OurSponsorship from "./screens/Sponsorship/OurSponsorship";
import Sponsorship from "./screens/Sponsorship/Sponsorship";
import Review from "./screens/LandingPage/components/Review";
import BlogDetails from "./screens/LandingPage/components/BlogDetails";
import Blogs from "./screens/LandingPage/components/Blogs";
import VerifyCertificate from "./screens/Verifycertificate/VerifyCertificate";
import BookDemo from "./screens/Bookdemo/BookDemo";
const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <UserAuthContextProvider>
          <Routes>
            <Route exact path="" element={<LandingPage />} />

            <Route path="/CourseDetails" element={<CourseDetails />} />
            <Route path="/JobDetails" element={<JobDetails />} />
            <Route path="/Disclaimer" element={<Disclaimer />} />
            <Route path="/Policy" element={<Policy />} />
            <Route path="/Conditions" element={<Conditions />} />
            <Route path="/Taglogin" element={<Taglogin />} />
            <Route path="/Reviewall" element={<Reviewall />} />
            <Route path="/Blogs" element={<Blogs />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route
              path="/Validatecertificate"
              element={<Validatecertificate />}
            />

            <Route path="/admission" element={<Admission />} />
            <Route path="/custompayment" element={<CustomPayment />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Pay" element={<Pay />} />
            <Route path="/Connect" element={<Connect />} />
            <Route path="/Photographycourse" element={<Contactus />} />
            <Route path="/AddYourReview" element={<AddYourReview />} />
            <Route path="/review" element={<Review />} />
            <Route path="/verifycertificate" element={<VerifyCertificate/>} />
            <Route path="/best-SAP-Institute-in-bangalore" element={<BookDemo/>} />

            <Route
              path="/*"
              element={
                <UserAuthContextProvider>
                  <Routes>
                    <Route path="/" element={<Main />}>
                      <Route
                        path="/dashboard"
                        index
                        element={
                          <ProtectedRoute>
                            <DashboardRevamp />
                            {/* <Dashboard /> */}
                          </ProtectedRoute>
                        }
                      />

                      <Route
                        path="/Pdfmaterials"
                        element={
                          <ProtectedRoute>
                            <Pdfmaterials />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/Coursevideo"
                        element={
                          <ProtectedRoute>
                            <Coursevideo />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/Certificate"
                        element={
                          <ProtectedRoute>
                            <Certificate />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/Videoplay"
                        element={
                          <ProtectedRoute>
                            <Videoplay />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/Profile"
                        element={
                          <ProtectedRoute>
                            {/* <Profile /> */}
                            <ProfileRevamp />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/Notification"
                      element={
                        <ProtectedRoute>
                          <Notification />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/payment"
                      element={
                        <ProtectedRoute>
                          <Payment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/Paystatus"
                      element={
                        <ProtectedRoute>
                          <Paystatus />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/" element={<OurSponsorship />} />
                    <Route path="/sponsorship" element={<Sponsorship />} />
                  </Routes>
                </UserAuthContextProvider>
              }
            />
          </Routes>
        </UserAuthContextProvider>

        {/* <UserAuthContextProvider>
            <Routes>
              <Route path="/"  element={<ProtectedRoute><Main/></ProtectedRoute>}>
              <Route path="/Dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}/>
              <Route path="/Pdfmaterials" element={<ProtectedRoute><Pdfmaterials /></ProtectedRoute>}/>
              <Route path="/Coursevideo" element={<ProtectedRoute><Coursevideo /></ProtectedRoute>}/>
              <Route path="/Certificate" element={<ProtectedRoute><Certificate /></ProtectedRoute>} />
              <Route path="/Blogs" element={<ProtectedRoute><Blogs /></ProtectedRoute>} />
              <Route path="/Popularvideos" element={<ProtectedRoute><Popularvideos /></ProtectedRoute>} />
              <Route path="/Videoplay" element={<ProtectedRoute><Videoplay /></ProtectedRoute>} />
              <Route path="/Profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />  
              </Route>
              <Route path="/Selecttype" element={<Selecttype />} />
              <Route path="/Selectcourse" element={<Selectcourse />} />
              <Route path="/Notification" element={<Notification />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/Paystatus" element={<Paystatus />} />
              <Route path="/Login" element={<Login />} />
            </Routes>
          </UserAuthContextProvider> */}
      </ScrollToTop>
    </BrowserRouter>
  );
};

const RequireAuth = ({ children }) => {
  let user = useSelector((state) => state.user);
  let location = useLocation();

  if (!user?.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};
export default App;
