import React, { useState, useEffect } from "react"
import { Link } from "react-scroll";
import styled from "styled-components";
import LogoIcon from "src/assets/icons/Logo.png";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Grid,
  TextField,
  Typography,
  CardMedia,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import CameraIcon from "@mui/icons-material/Camera";
import { Check } from "@mui/icons-material";
import ActivityIndicator from "src/components/ActivityIndicator/ActivityIndicator"
import {
    Facebook,
    Instagram,
    LinkedIn,
    Mail,
    WhatsApp,
    YouTube,
  } from "@mui/icons-material";
  import gIcon from "src/assets/images/gIcon.png";
  import PeopleIcon from '@mui/icons-material/People';
  import { BookDemoApi ,MailSendingApi} from "src/api/ApiConnect"
import { PostWitoutToken } from "src/api/apiConfig";
import camar from "src/assets/images/camar.svg";
import camtwo from "src/assets/images/camtwo.svg";
import camthree from "src/assets/images/camthree.svg";
import camfour from "src/assets/images/camfour.svg";
import camfive from "src/assets/images/camfive.svg";
import { useNavigate } from "react-router-dom";
import BookNowCoralFrame from "./BookNowCoralFrame";


export default function Contactus() {

  const navigate = useNavigate();

  const [openJoinFor, setOpenJoinFor] = useState(false);

    const [loading, setLoading] = useState()
    //variable declaration 
    const queryParams = new URLSearchParams(window.location.search)
  const getcouponcode = queryParams.get("coupon")
  
  const [localfullname, setlocalfullname] = useState("");
  const [localmobile, setlocalmobile] = useState("");
  const [localemail, setlocalemail] = useState("");
  const [couponcode, setCouponcode] = useState(getcouponcode || "");

  //Validation declaration
const [localfullnameError, setlocalfullnameError] = useState(null);
const [localmobileError, setlocalmobileError] = useState(null);
const [localemailError, setlocalemailError] = useState(null);

const onSubmit= async (e) => {

    //Validation Check
    const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const pattern = new RegExp(/^[0-9\b]+$/)

    setlocalfullnameError(null);
    setlocalmobileError(null);
    setlocalemailError(null);


    if(localfullname.length==0)
    {
      setlocalfullnameError("Please enter your full name");
      return;
    }

    if(localmobile.length==0)
    {
      setlocalmobileError("Please enter your mobile number");
      return;
    }


    if(localemail.length==0)
    {
        setlocalemailError("Please enter your email");
      return;
    }

    if (!re.test(localemail)) {
        setlocalemailError("Enter valid Email Id")
        return
      }

    setLoading(true)

   
    //Api call
     const RequestBody = { 
        USER_FULLNAME: localfullname,
        USER_MOBILE: localmobile,
        USER_EMAIL: localemail,
        COURSE_CATEGORY: 'ERP',
        SOURCE:'CURIOSTUFF',
        COUPON: couponcode?.toUpperCase()
       }
      
       const data = await PostWitoutToken(BookDemoApi,RequestBody)
       mailsending();
       setLoading(false)
       if (data?.IsSuccess) {
         alert(data.Message)
       }else{
        alert(data.Message)
       }
  }

  const mailsending= async (e) => {

    const now = new Date();
    const formattedDate = now.toLocaleString();

    const RequestBody = { 
      NAME: localfullname,
      TIME: formattedDate
     }
    const data = await PostWitoutToken(MailSendingApi,RequestBody)
  }

  const handleDialogClose = () => {
    setOpenJoinFor(false);
  };

  return (
    <>
      {/* Top Bar */}
      <AppBar position="static" color="primary">
        <Toolbar sx={{ background: "#411882" }}>
          {/* Logo */}
          <IconButton
            edge="start"
            color="inherit"
            aria-label="logo"
            sx={{ mr: 2 }}
          >
            <img
              //   className={styles.navLogo}
              style={{
                width: "36px",
                height: "36px",
                alignSelf: "flex-start",
                // marginRight: "16px",
              }}
              alt="logo.png"
              src={LogoIcon}
            />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              fontWeight: "bold",
              textTransform: "none",
            }}
          >
            Tagskills
          </Typography>
          {/* Navigation Links */}
          {/* <Button color="inherit">Home</Button>
        <Button color="inherit">Courses</Button>
        <Button color="inherit">Gallery</Button>
        <Button color="inherit">Contact</Button> */}
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        bgcolor="white"
        sx={{mt:-4}}
      >
        {/* Section 1 */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          minHeight="100vh"
          bgcolor="white"
        >
          {/* Left Section */}
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={{ xs: "center", md: "flex-start" }}
            px={6}
            py={{ xs: 4, md: 0 }}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
          >
            <Typography
              variant="h2"
              fontWeight="bold"
              color="primary"
              gutterBottom
              sx={{ lineHeight: 1.2 }}
            >
              Make Your Fashion <br />
              More Perfect. <br />
            </Typography>
            <Typography variant="body1" color="textSecondary" mb={4}>
              Welcome to our comprehensive Photography Course, where passion
              meets technique and creativity transforms into art. Whether you're
              an aspiring photographer or looking to elevate your skills, our
              course is designed to empower you to capture life's most beautiful
              moments.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                boxShadow: 3,
                ":hover": { backgroundColor: "primary.dark" },
                textTransform: "none",
              }}
              onClick={() => setOpenJoinFor(true)}
            >
              Enroll Now
            </Button>
          </Box>

          {/* Right Section */}
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            px={6}
          >
            <Grid container spacing={2} sx={{ maxWidth: 600 }}>
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  image={camar}
                  alt="Main Camera"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 0,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image={camtwo}
                  alt="Camera Closeup"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 0,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image={camthree}
                  alt="Photographer"
                  sx={{
                    width: "100%",
                    height: "auto",
                    borderRadius: 2,
                    boxShadow: 0,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* Section 2 */}
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          minHeight="50vh"
          px={6}
          py={4}
        >
          {/* Left Section - Image */}
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            px={6}
          >
            <CardMedia
              component="img"
              image={camfour}
              alt="Photography Course"
              sx={{
                width: "100%",
                maxWidth: 600,
                height: "auto",
                borderRadius: 2,
                boxShadow: 0,
              }}
            />
          </Box>

          {/* Right Section - Content */}
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            px={6}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              color="primary"
              gutterBottom
              sx={{ textAlign: { xs: "center", md: "left" } }}
            >
              Why Choose Our Photography Course?
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mb: 3, lineHeight: 1.8 }}
            >
              <strong>Expert Instruction:</strong> Learn from seasoned
              professionals who bring years of industry experience and artistic
              insight.
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mb: 3, lineHeight: 1.8 }}
            >
              <strong>Hands-On Learning:</strong> Engage in interactive
              workshops, practical assignments, and real-world projects to build
              your portfolio.
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mb: 3, lineHeight: 1.8 }}
            >
              <strong>Comprehensive Curriculum:</strong> From camera basics to
              advanced lighting techniques, explore every facet of photography.
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mb: 3, lineHeight: 1.8 }}
            >
              <strong>Flexible Learning Options:</strong> Choose from in-person
              classes, live online sessions, or self-paced modules that fit your
              schedule.
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ mb: 4, lineHeight: 1.8 }}
            >
              <strong>State-of-the-Art Facilities:</strong> Gain access to
              top-of-the-line equipment and creative studio spaces designed for
              immersive learning.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                borderRadius: "50px",
                px: 4,
                py: 1.5,
                boxShadow: 3,
                alignSelf: { xs: "center", md: "flex-start" },
                ":hover": { backgroundColor: "primary.dark" },
                textTransform: "none",
              }}
              onClick={() => setOpenJoinFor(true)}
            >
              Enroll Now
            </Button>
          </Box>
        </Box>
      </Box>
      {/* What You’ll Learn Section */}
      <Box px={6} py={6} bgcolor="#f9f9f9">
        <Typography
          variant="h4"
          fontWeight="bold"
          color="primary"
          gutterBottom
          sx={{ textAlign: { xs: "center", md: "left" }, mb: 4 }}
        >
          What You’ll Learn
        </Typography>
        <Box>
          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            1. Camera Mastery
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            Understand your equipment inside and out. Learn manual settings and
            explore the creative potential of your camera. Practice composition,
            exposure, and focus techniques.
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            2. Lighting Techniques
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            Discover the fundamentals of natural and artificial lighting. Master
            studio lighting setups and on-location shooting. Explore creative
            lighting to enhance mood and depth in your photographs.
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            3. Post-Processing Essentials
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            Get hands-on training with industry-standard software like Adobe
            Lightroom and Photoshop. Learn editing techniques to enhance,
            retouch, and transform your images. Understand color correction,
            cropping, and creative effects.
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            4. The Art of Storytelling
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            Develop your unique visual style. Learn how to convey powerful
            narratives through your images. Study the work of influential
            photographers for inspiration.
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            5. Special Topics & Advanced Techniques
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            Dive into niche areas such as portrait, landscape, wildlife, and
            macro photography. Explore the intricacies of commercial and fine
            art photography. Engage in projects that challenge your creativity
            and technical skills.
          </Typography>

          <Typography
            variant="h6"
            fontWeight="bold"
            color="textPrimary"
            gutterBottom
          >
            6. Real-Time Photography Experience
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ lineHeight: 1.8 }}
          >
            Live Shoot Sessions: Gain hands-on experience by working directly
            with international models in dynamic, real-world settings. Real-Time
            Projects: Participate in live projects that simulate professional
            assignments, allowing you to build an impressive, diverse portfolio.
            Collaboration & Networking: Work alongside industry experts and
            peers, expanding your professional network while learning the
            nuances of on-location photography.
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        minHeight="50vh"
        px={6}
        py={4}
      >
        {/* Left Section - Image */}
        <Box
          flex={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          px={6}
        >
          <CardMedia
            component="img"
            image={camfive}
            alt="Photography Course"
            sx={{
              width: "100%",
              maxWidth: 600,
              height: "auto",
              borderRadius: 2,
              boxShadow: 0,
            }}
          />
        </Box>

        {/* Right Section - Content */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={6}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            color="primary"
            gutterBottom
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            Why Choose Us?
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <LinkedIn
                onClick={() =>
                  window.open(
                    "https://www.linkedin.com/company/tag-skills",
                    "_blank"
                  )
                }
                sx={{ cursor: "pointer", fill: "#68a3da" }}
              />
              <Typography>22.26K+ Followers</Typography>
            </Box>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <YouTube
                onClick={() =>
                  window.open(
                    "https://www.youtube.com/channel/UCnrhyp2JvcNQb3kFeRziDLA",
                    "_blank"
                  )
                }
                sx={{ cursor: "pointer", fill: "#ff2525" }}
              />
              <Typography >9.76k+ Subscribers</Typography>
            </Box>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
            <Box display="flex" alignItems="center" gap={1}>
              <img
                src={gIcon}
                alt="Google Icon"
                style={{ width: "20px", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.google.com/search?q=tagskills",
                    "_blank"
                  )
                }
              />
              <Typography >4.9 Rating on Google</Typography>
            </Box>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 3, lineHeight: 1.8 }}
          >
             <Box display="flex" alignItems="center" gap={1}>
              <PeopleIcon sx={{ fill: "#ff2525" }} />
              <Typography >1K+ Placements</Typography>
            </Box>
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ mb: 4, lineHeight: 1.8 }}
          >
              {[
              "Expert Trainers",
              "Business process knowledge",
              "Real time project",
              "Consultative approach",
              "Placement support",
            ].map((text, index) => (
              <Box key={index} display="flex" alignItems="center" gap={1}>
                <Check />
                <Typography >{text}</Typography>
              </Box>
            ))}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{
              borderRadius: "50px",
              px: 4,
              py: 1.5,
              boxShadow: 3,
              alignSelf: { xs: "center", md: "flex-start" },
              ":hover": { backgroundColor: "primary.dark" },
              textTransform: "none",
            }}
            onClick={() => setOpenJoinFor(true)}
          >
            Enroll Now
          </Button>
        </Box>
      </Box>

      {openJoinFor && (
        <BookNowCoralFrame onClose={handleDialogClose} openJoinFor={openJoinFor} />
      )}
    </>
  );
}

