import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { BookDemoApi,MailSendingApi } from "src/api/ApiConnect";
import { PostWitoutToken } from "src/api/apiConfig";
import { Input } from "reactstrap";
import { useNavigate } from "react-router-dom";

export default function BookNowCoralFrame(props) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [couponcode, setCouponcode] = useState("");
  const [loading, setLoading] = useState();

  const [msgfullname, setFullnamemsg] = useState("");
  const [msgfullnamebool, setFullnamemsgbool] = useState(false);

  const [msgmobilenumber, setMobilenumbermsg] = useState("");
  const [msgmobilenumberbool, setMobilenumbermsgbool] = useState(false);

  const [msgemaiid, setEmailidmsg] = useState("");
  const [msgemaiidbool, setEmailidmsgbool] = useState(false);

  const onSubmit = async (e) => {
    if (name === "") {
      setFullnamemsgbool(true);
      setFullnamemsg("Enter Your Full Name!");
      return;
    }

    if (number === "") {
      setMobilenumbermsgbool(true);
      setMobilenumbermsg("Enter Your Mobile Number!");
      return;
    }

    if (email === "") {
      setEmailidmsgbool(true);
      setEmailidmsg("Enter Your Email!");
      return;
    }

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!re.test(String(email).toLowerCase())) {
      setEmailidmsgbool(true);
      setEmailidmsg("Enter Valid EmailId!");
      return;
    }

    setLoading(true);


    const apiUrl = "https://teazelapi.coralframes.com/api/contactus";
    const payload = {
      NAME: name,
      CONTACTNUMBER: number,
      EMAIL: email,
      COMPANY: 'STUDENT',
      DATASOURCE: "TAGSKILLS"
    };
    

    try {
        const response = await PostWitoutToken(apiUrl, payload);
        if(response?.IsSuccess)
        {
          alert('Thank You for Reaching Out! Our team will get back to you as soon as possible.')
        }else{
          alert('Something went wrong!. Please try again.')
        }
      
      } catch (err) {
        alert(err.message); // Handle error
      }

  };

  const mailsending= async (e) => {

    const now = new Date();
    const formattedDate = now.toLocaleString();

    const RequestBody = { 
      NAME: name,
      TIME: formattedDate
     }
    const data = await PostWitoutToken(MailSendingApi,RequestBody)
  }
  return (
    <div>
      <Dialog open={props?.openJoinFor}>
        <DialogTitle>
          <Typography variant="h6" style={{ textAlign: "center" }}>
            <b>Book Free Consultation</b>
            <span style={{ float: "right", cursor: "pointer" }}>
              <Close onClick={() => props?.onClose(false)} />
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <br />
            <TextField
              variant="outlined"
              size="small"
              label="Enter Your Full Name"
              className="pb10"
              style={{ width: "100%" }}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (e.target.value != "") {
                  setFullnamemsgbool(false);
                  setFullnamemsg("");
                }
              }}
              helperText={msgfullname}
              error={msgfullnamebool}
            />
            <br />

            <TextField
              variant="outlined"
              size="small"
              className="pb10"
              label="Enter Your Mobile number"
              type="number"
              style={{ width: "100%" }}
              value={number}
              onChange={(e) => {
                setNumber(e.target.value);
                if (e.target.value != "") {
                  setMobilenumbermsgbool(false);
                  setMobilenumbermsg("");
                }
              }}
              onKeyDown={(e) => {
                ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
                if (
                  number.length >= 12 &&
                  ![
                    "Backspace",
                    "ArrowLeft",
                    "ArrowRight",
                    "Tab",
                    "Delete",
                  ].includes(e.key)
                ) {
                  e.preventDefault();
                }
              }}
              helperText={msgmobilenumber}
              error={msgmobilenumberbool}
            />

            <br />

            <TextField
              variant="outlined"
              size="small"
              label="Enter Your EmailId"
              className="pb10"
              style={{ width: "100%" }}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (e.target.value != "") {
                  setEmailidmsgbool(false);
                  setEmailidmsg("");
                }
              }}
              helperText={msgemaiid}
              error={msgemaiidbool}
            />
        
            <br />
            <Typography
              variant="body2"
              className="font11 pb10"
              style={{ textAlign: "center" }}
            >
              By clicking Submit. you agree to achive communications from
              Tagskills in accordance with our{" "}
              <a
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => navigate("/Policy")}
              >
                <u>Privacy Policy</u>
              </a>
            </Typography>
            <Button
              style={{
                background: "#5249BD",
                color: "white",
                width: "50%",
                borderRadius: "24px",
              }}
              variant="contained"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
